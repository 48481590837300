import { combineReducers } from "@reduxjs/toolkit";
// auth
import loginReducer from "./auth/login.slice";
import createVerifyRoleLogReducer from "./auth/createVerifyRoleLog.slice";
import createOrUpdateNewAlldernowUserReducer from "./auth/createOrUpdateNewAlldernowUser.slice";
import getUserInfoReducer from "./auth/getUserInfo.slice";
// master
import getAllMstPrefixReducer from "./master/getAllMstPrefix.slice";
import getAllMstSexReducer from "./master/getAllMstSex.slice";
import getAllMstMaritalStatusReducer from "./master/getAllMstMaritalStatus.slice";
import getAllMstMilitaryStatusReducer from "./master/getAllMstMilitaryStatus.slice";
import getAllMstRelationshipReducer from "./master/getAllMstRelationship.slice";
import getAllMstEmployeeTypeReducer from "./master/getAllMstEmployeeType.slice";
import getAllMstDepartmentReducer from "./master/getAllMstDepartment.slice";
import getAllMstPositionReducer from "./master/getAllMstPosition.slice";
import getAllMstBankReducer from "./master/getAllMstBank.slice";
import getAllMstBankAccountTypeReducer from "./master/getAllMstBankAccountType.slice";
import getAllMstEmployeeDocReducer from "./master/getAllMstEmployeeDoc.slice";
import getAllMstBranchReducer from "./master/getAllMstBranch.slice";
import getAllMstBusinessTypeReducer from "./master/getAllMstBusinessType.slice";
import getAllMstBusinessCategoryReducer from "./master/getAllMstBusinessCategory.slice";
import getAllMstCompanyPrefixReducer from "./master/getAllMstCompanyPrefix.slice";
import getAllMstPaymentMethodReducer from "./master/getAllMstPaymentMethod.slice";
import getAllMstPaymentTermReducer from "./master/getAllMstPaymentTerm.slice";
import getAllMstSupplierDocReducer from "./master/getAllMstSupplierDoc.slice";
import getAllMstNewAlldernowRoleReducer from "./master/getAllMstNewAlldernowRole.slice";
import getAllMstPaymentTypeReducer from "./master/getAllMstPaymentType.slice";
import getAllMstDiscountTypeReducer from "./master/getAllMstDiscountType.slice";
import getAllMstPriceCategoryReducer from "./master/getAllMstPriceCategory.slice";
import getAllMstVatReducer from "./master/getAllMstVat.slice";
import getAllWarehouseReducer from "./master/getAllWarehouse.slice";
import getAllMasterBrandReducer from "./master/getAllMasterBrand.slice";
import getAllMasterMainCategoryReducer from "./master/getAllMasterMainCategory.slice";
import getAllMstNewItemPrColReducer from "./master/getAllMstNewItemPrCol.slice";
import getAllMstOfficeSectionReducer from "./master/getAllMstOfficeSection.slice";
import getFirstMstCompanyReducer from "./master/getFirstMstCompany.slice";
import getFirstMstMartReducer from "./master/getFirstMstMart.slice";
import getAllMstAddCostReducer from "./master/getAllMstAddCost.slice";
import getAllMstAddCostUnitReducer from "./master/getAllMstAddCostUnit.slice";
import getAllMstNewItemExpenseColReducer from "./master/getAllMstNewItemExpenseCol.slice";
import getAllMstWithholdingTaxReducer from "./master/getAllMstWithholdingTax.slice";
import getAllMstExpenseTypeReducer from "./master/getAllMstExpenseType.slice";
// logic
import createNewAlldernowEmpReducer from "./logic/createNewAlldernowEmp.slice";
import updateNewAlldernowEmpReducer from "./logic/updateNewAlldernowEmp.slice";
import getAllNewAlldernowEmpReducer from "./logic/getAllNewAlldernowEmp.slice";
import getNewAlldernowEmpByIdReducer from "./logic/getNewAlldernowEmpById.slice";
import createSupplierInformationReducer from "./logic/createSupplierInformation.slice";
import updateSupplierInformationReducer from "./logic/updateSupplierInformation.slice";
import getAllSupplierInformationReducer from "./logic/getAllSupplierInformation.slice";
import getSupplierInformationByIdReducer from "./logic/getSupplierInformationById.slice";
import createGroupOfSupplierReducer from "./logic/createGroupOfSupplier.slice";
import updateGroupOfSupplierReducer from "./logic/updateGroupOfSupplier.slice";
import getGroupOfSupplierByIdReducer from "./logic/getGroupOfSupplierById.slice";
import getAllGroupOfSupplierReducer from "./logic/getAllGroupOfSupplier.slice";
import getAllProductPriceReducer from "./logic/getAllProductPrice.slice";
import getProductPriceByIdReducer from "./logic/getProductPriceById.slice";
import createNewPurchaseReqReducer from "./logic/createNewPurchaseReq.slice";
import updateNewPurchaseReqReducer from "./logic/updateNewPurchaseReq.slice";
import getAllNewPurchaseReqReducer from "./logic/getAllNewPurchaseReq.slice";
import getNewPurchaseReqByIdReducer from "./logic/getNewPurchaseReqById.slice";
import genNewPurchaseReqDocNumberReducer from "./logic/genNewPurchaseReqDocNumber.slice";
import createNewPurchaseOrderReducer from "./logic/createNewPurchaseOrder.slice";
import updateNewPurchaseOrderReducer from "./logic/updateNewPurchaseOrder.slice";
import getAllNewPurchaseOrderReducer from "./logic/getAllNewPurchaseOrder.slice";
import getNewPurchaseOrderByIdReducer from "./logic/getNewPurchaseOrderById.slice";
import genNewPurchaseOrderDocNumberReducer from "./logic/genNewPurchaseOrderDocNumber.slice";
import getAllNewPoSplitReducer from "./logic/getAllNewPoSplit.slice";
import getNewPoSplitByIdReducer from "./logic/getNewPoSplitById.slice";
import genNewGrDocNumberReducer from "./logic/genNewGrDocNumber.slice";
import createNewGrReducer from "./logic/createNewGr.slice";
import getAllNewGrReducer from "./logic/getAllNewGr.slice";
import getNewGrByIdReducer from "./logic/getNewGrById.slice";
import updateNewGrReducer from "./logic/updateNewGr.slice";
import getHQAddressByDateReducer from "./logic/getHQAddressByDate.slice";
import createExpenseReducer from "./logic/createExpense.slice";
import updateExpenseReducer from "./logic/updateExpense.slice";
import getAllExpenseReducer from "./logic/getAllExpense.slice";
import getExpenseByIdReducer from "./logic/getExpenseById.slice";
import genExpenseDocNumberReducer from "./logic/genExpenseDocNumber.slice";
// report
import getAllReportPurchaseProductPoItemReducer from "./report/getAllReportPurchaseProductPoItem.slice";
import getAllReportPurchaseSupplierReducer from "./report/getAllReportPurchaseSupplier.slice";
import getAllReportPurchaseOrderReducer from "./report/getAllReportPurchaseOrder.slice";
import getAllReportReceiveProductGrItemReducer from "./report/getAllReportReceiveProductGrItem.slice";
var appReducer = combineReducers({
    loginReducer: loginReducer,
    createVerifyRoleLogReducer: createVerifyRoleLogReducer,
    createOrUpdateNewAlldernowUserReducer: createOrUpdateNewAlldernowUserReducer,
    getUserInfoReducer: getUserInfoReducer,
    getAllMstPrefixReducer: getAllMstPrefixReducer,
    getAllMstSexReducer: getAllMstSexReducer,
    getAllMstMaritalStatusReducer: getAllMstMaritalStatusReducer,
    getAllMstMilitaryStatusReducer: getAllMstMilitaryStatusReducer,
    getAllMstRelationshipReducer: getAllMstRelationshipReducer,
    getAllMstEmployeeTypeReducer: getAllMstEmployeeTypeReducer,
    getAllMstDepartmentReducer: getAllMstDepartmentReducer,
    getAllMstPositionReducer: getAllMstPositionReducer,
    getAllMstBankReducer: getAllMstBankReducer,
    getAllMstBankAccountTypeReducer: getAllMstBankAccountTypeReducer,
    getAllMstEmployeeDocReducer: getAllMstEmployeeDocReducer,
    getAllMstBranchReducer: getAllMstBranchReducer,
    getAllMstBusinessTypeReducer: getAllMstBusinessTypeReducer,
    getAllMstBusinessCategoryReducer: getAllMstBusinessCategoryReducer,
    getAllMstCompanyPrefixReducer: getAllMstCompanyPrefixReducer,
    getAllMstPaymentMethodReducer: getAllMstPaymentMethodReducer,
    getAllMstPaymentTermReducer: getAllMstPaymentTermReducer,
    getAllMstSupplierDocReducer: getAllMstSupplierDocReducer,
    getAllMstNewAlldernowRoleReducer: getAllMstNewAlldernowRoleReducer,
    getAllMstPaymentTypeReducer: getAllMstPaymentTypeReducer,
    getAllMstDiscountTypeReducer: getAllMstDiscountTypeReducer,
    getAllMstPriceCategoryReducer: getAllMstPriceCategoryReducer,
    getAllMstVatReducer: getAllMstVatReducer,
    getAllWarehouseReducer: getAllWarehouseReducer,
    getAllMasterBrandReducer: getAllMasterBrandReducer,
    getAllMasterMainCategoryReducer: getAllMasterMainCategoryReducer,
    getAllMstNewItemPrColReducer: getAllMstNewItemPrColReducer,
    getAllMstOfficeSectionReducer: getAllMstOfficeSectionReducer,
    getFirstMstCompanyReducer: getFirstMstCompanyReducer,
    getFirstMstMartReducer: getFirstMstMartReducer,
    getAllMstAddCostReducer: getAllMstAddCostReducer,
    getAllMstAddCostUnitReducer: getAllMstAddCostUnitReducer,
    getAllMstNewItemExpenseColReducer: getAllMstNewItemExpenseColReducer,
    getAllMstWithholdingTaxReducer: getAllMstWithholdingTaxReducer,
    getAllMstExpenseTypeReducer: getAllMstExpenseTypeReducer,
    createNewAlldernowEmpReducer: createNewAlldernowEmpReducer,
    updateNewAlldernowEmpReducer: updateNewAlldernowEmpReducer,
    getAllNewAlldernowEmpReducer: getAllNewAlldernowEmpReducer,
    getNewAlldernowEmpByIdReducer: getNewAlldernowEmpByIdReducer,
    createSupplierInformationReducer: createSupplierInformationReducer,
    updateSupplierInformationReducer: updateSupplierInformationReducer,
    getAllSupplierInformationReducer: getAllSupplierInformationReducer,
    getSupplierInformationByIdReducer: getSupplierInformationByIdReducer,
    createGroupOfSupplierReducer: createGroupOfSupplierReducer,
    updateGroupOfSupplierReducer: updateGroupOfSupplierReducer,
    getAllGroupOfSupplierReducer: getAllGroupOfSupplierReducer,
    getGroupOfSupplierByIdReducer: getGroupOfSupplierByIdReducer,
    getAllProductPriceReducer: getAllProductPriceReducer,
    getProductPriceByIdReducer: getProductPriceByIdReducer,
    createNewPurchaseReqReducer: createNewPurchaseReqReducer,
    updateNewPurchaseReqReducer: updateNewPurchaseReqReducer,
    getAllNewPurchaseReqReducer: getAllNewPurchaseReqReducer,
    getNewPurchaseReqByIdReducer: getNewPurchaseReqByIdReducer,
    genNewPurchaseReqDocNumberReducer: genNewPurchaseReqDocNumberReducer,
    createNewPurchaseOrderReducer: createNewPurchaseOrderReducer,
    updateNewPurchaseOrderReducer: updateNewPurchaseOrderReducer,
    getAllNewPurchaseOrderReducer: getAllNewPurchaseOrderReducer,
    getNewPurchaseOrderByIdReducer: getNewPurchaseOrderByIdReducer,
    genNewPurchaseOrderDocNumberReducer: genNewPurchaseOrderDocNumberReducer,
    getAllNewPoSplitReducer: getAllNewPoSplitReducer,
    getNewPoSplitByIdReducer: getNewPoSplitByIdReducer,
    genNewGrDocNumberReducer: genNewGrDocNumberReducer,
    createNewGrReducer: createNewGrReducer,
    getAllNewGrReducer: getAllNewGrReducer,
    getNewGrByIdReducer: getNewGrByIdReducer,
    updateNewGrReducer: updateNewGrReducer,
    getHQAddressByDateReducer: getHQAddressByDateReducer,
    createExpenseReducer: createExpenseReducer,
    updateExpenseReducer: updateExpenseReducer,
    getAllExpenseReducer: getAllExpenseReducer,
    getExpenseByIdReducer: getExpenseByIdReducer,
    genExpenseDocNumberReducer: genExpenseDocNumberReducer,
    getAllReportPurchaseProductPoItemReducer: getAllReportPurchaseProductPoItemReducer,
    getAllReportPurchaseSupplierReducer: getAllReportPurchaseSupplierReducer,
    getAllReportPurchaseOrderReducer: getAllReportPurchaseOrderReducer,
    getAllReportReceiveProductGrItemReducer: getAllReportReceiveProductGrItemReducer
});
var rootReducer = function(state, action) {
    if (action.type === "USER_LOGOUT") {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};
export default rootReducer;
