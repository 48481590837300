import { _ as _object_spread } from "@swc/helpers/_/_object_spread";
import { _ as _object_spread_props } from "@swc/helpers/_/_object_spread_props";
import { _ as _sliced_to_array } from "@swc/helpers/_/_sliced_to_array";
import { nanoid } from "@reduxjs/toolkit";
export function mappingoptionsNewItemExpenseCol(options) {
    return options.map(function(item) {
        var sortKey = "";
        switch(item.name_th){
            case "รหัสสินค้า":
                sortKey = "productCode";
                break;
            case "รายการ":
                sortKey = "productName";
                break;
            case "หน่วย":
                sortKey = "productUnit";
                break;
            case "จำนวน":
                sortKey = "productQty";
                break;
            case "ราคา/หน่วย":
                sortKey = "productPrice";
                break;
            case "ประเภทส่วนลด":
                sortKey = "discountType";
                break;
            case "ส่วนลด":
                sortKey = "discount";
                break;
            case "ภาษี":
                sortKey = "vat";
                break;
            case "มูลค่าก่อนภาษี":
                sortKey = "valueBeforeVat";
                break;
            case "มูลค่ารวมภาษี":
                sortKey = "valueIncludingVat";
                break;
            default:
                break;
        }
        return {
            label: item.name_th,
            value: item.id,
            sortKey: sortKey
        };
    });
}
export function mappingExpense(param) {
    var expenseData = param.expenseData, setPdata = param.setPdata, setProductRow = param.setProductRow, setFormdisabled = param.setFormdisabled, columnsort = param.columnsort, setColumnsort = param.setColumnsort;
    var dataWithReplacements = Object.entries(expenseData).reduce(function(acc, param) {
        var _param = _sliced_to_array(param, 2), key = _param[0], value = _param[1];
        acc[key] = value === null ? "" : value;
        return acc;
    }, {});
    if (dataWithReplacements.dimSupplierInformation) {
        var dimSupplierInformation = dataWithReplacements.dimSupplierInformation;
        var full_supplier_name = dataWithReplacements.full_supplier_name, full_supplier_address_name = dataWithReplacements.full_supplier_address_name;
        setPdata(function(prev) {
            return {
                id: dimSupplierInformation.id,
                dimGroupOfSupplierCode: dimSupplierInformation.dimGroupOfSupplier ? dimSupplierInformation.dimGroupOfSupplier.code : "-",
                full_supplier_name: full_supplier_name,
                initials: dimSupplierInformation.initials,
                company_branch_code: dimSupplierInformation.company_branch_code,
                company_branch_name: dimSupplierInformation.company_branch_name,
                full_supplier_address_name: full_supplier_address_name,
                company_phone_number: dimSupplierInformation.company_phone_number
            };
        });
    }
    if (dataWithReplacements.factExpenseItem && dataWithReplacements.factExpenseItem.length > 0) {
        var productItems = dataWithReplacements.factExpenseItem.map(function(v) {
            var _dimMstAddCost_dimProduct;
            var dim_mst_add_cost_id = v.dim_mst_add_cost_id, quantity = v.quantity, unit_price = v.unit_price, dim_mst_add_cost_unit_id = v.dim_mst_add_cost_unit_id, ratio_unit = v.ratio_unit, container = v.container, dim_mst_discount_type_id = v.dim_mst_discount_type_id, remark = v.remark, discount_value = v.discount_value, discount_price = v.discount_price, dimMstAddCost = v.dimMstAddCost, dimMstAddCostUnit = v.dimMstAddCostUnit, dimMstVat = v.dimMstVat, dimMstWithholdingTax = v.dimMstWithholdingTax;
            // console.log(discount_price);
            var mappingData = {
                rowID: nanoid(),
                productID: dim_mst_add_cost_id,
                productCode: "",
                productName: dimMstAddCost.name,
                productBarcode: "",
                productUnitID: dim_mst_add_cost_unit_id,
                productRatioUnit: 1,
                productContainer: container,
                supplierCode: (_dimMstAddCost_dimProduct = dimMstAddCost.dimProduct) === null || _dimMstAddCost_dimProduct === void 0 ? void 0 : _dimMstAddCost_dimProduct.supplier_code,
                warehouseID: "",
                warehouseName: "",
                productUnit: dimMstAddCostUnit ? dimMstAddCostUnit.name : "-",
                productQty: quantity,
                productOrdered: 0,
                ums: "",
                productStock: "",
                productPrice: unit_price,
                discountType: dim_mst_discount_type_id,
                discount: discount_price,
                discountPercent: discount_value,
                tax: "",
                price_before_tax: 0,
                dim_mst_vat_id: dimMstVat.id,
                vat: dimMstVat.value,
                dim_mst_withholding_tax_id: dimMstWithholdingTax ? dimMstWithholdingTax.id : "",
                note: remark,
                price_after_tax: 0,
                not_approve_reason: "",
                is_checked: false,
                edit: false
            };
            return mappingData;
        });
        dataWithReplacements.products = productItems;
        setProductRow(productItems);
    }
    if (dataWithReplacements.dimMstExpenseStatus && dataWithReplacements.dimMstExpenseStatus.id > 2) {
        setFormdisabled(true);
    }
    dataWithReplacements.dim_mst_expense_status_id = "";
    dataWithReplacements.fact_new_item_expense_sorts = [];
    if (dataWithReplacements.factNewItemExpenseSort && dataWithReplacements.factNewItemExpenseSort.length > 0) {
        var mappingCols = dataWithReplacements.factNewItemExpenseSort.map(function(v) {
            return {
                id: v.id,
                sortKey: v.name
            };
        });
        while(mappingCols.length <= 3){
            mappingCols.push({
                id: "",
                sortKey: ""
            });
        }
        setColumnsort(_object_spread_props(_object_spread({}, columnsort), {
            columnsort: mappingCols
        }));
    }
    return dataWithReplacements;
}
