import moment from "moment";
export var testState = {
    dim_mst_new_status_id: 1,
    dim_mst_payment_type_id: 1,
    dim_mst_price_category_id: 1,
    dim_new_alldernow_emp_id: 4,
    dim_supplier_information_id: 7,
    full_supplier_name: "Supplier Co.",
    full_supplier_address_name: "123 Supplier St., City, Country",
    supplier_phone_number: "+1234567890",
    supplier_nickname: "Supplier Nickname",
    is_one_receiving_point: true,
    dim_warehouse_id: 3,
    dim_warehouse_name: "002-คลังสำนักงานใหญ่",
    refer: "INV-241200014",
    dim_mst_discount_type_id: 1,
    discount_value: 10.0,
    total_price_before_discount: 1000.0,
    discount_price: 950.0,
    total_price_after_discount: 900.0,
    total_price_product_no_vat: 800.0,
    total_price_product_zero_vat: 0.0,
    total_price_product_seven_vat: 700.0,
    total_price_vat: 50.0,
    grand_total_price: 1050.0,
    remark: "Handle with care.",
    doc_issue_date: "2024-05-01T12:00:00Z",
    delivery_date: "2024-05-10T12:00:00Z",
    delivery_time: "14:15",
    is_sort_manual: true,
    products: [],
    dim_new_item_pr_sorts: [],
    doc_number: "GR-2410000001",
    po_refer: "PO-2410000001-1",
    createdNewPrEmp: "นาย มงคล จิตดี"
};
export var initState = {
    dim_new_po_split_id: "",
    po_split_refer: "",
    dim_mst_new_status_id: "",
    dim_mst_payment_type_id: "",
    dim_mst_price_category_id: 2,
    dim_new_alldernow_emp_id: "",
    dim_supplier_information_id: "",
    full_supplier_name: "",
    full_supplier_address_name: "",
    supplier_phone_number: "",
    supplier_nickname: "",
    is_one_receiving_point: true,
    dim_warehouse_id: "",
    dim_warehouse_name: "",
    refer: "",
    dim_mst_discount_type_id: 1,
    discount_value: 0,
    total_price_before_discount: 0,
    discount_price: 0,
    total_price_after_discount: 0,
    total_price_product_no_vat: 0,
    total_price_product_zero_vat: 0,
    total_price_product_seven_vat: 0,
    total_price_vat: 0,
    grand_total_price: 0,
    remark: "",
    doc_issue_date: moment(new Date()).format("YYYY-MM-DD"),
    delivery_date: "",
    delivery_time: "",
    is_sort_manual: true,
    not_approve_reason: "",
    products: [],
    dim_new_item_pr_sorts: [],
    dimNewPurchaseOrder: [],
    doc_number: "",
    po_refer: "",
    doc_due_date: "",
    dim_mst_financial_status_id: "",
    dim_mst_expense_type_id: "",
    is_manual_expense: false,
    edit_financial_reason: ""
};
export var initGrMstStatusCount = {
    "1": 0,
    "2": 0,
    "3": 0,
    "4": 0,
    "5": 0,
    "6": 0,
    "7": 0,
    "finanCancel": 0,
    "null": 0
};
export var initFinancialStatusCount = {
    "1": 0,
    "2": 0,
    "3": 0,
    "4": 0,
    "5": 0,
    "6": 0,
    "7": 0,
    "finanCancel": 0,
    "null": 0
};
