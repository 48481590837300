import moment from "moment";
import { calPriceByMstCategoryPriceTax, calPriceByMstCategoryPriceTaxSeparate, getEmpFullNameTHByEmpData, getSupplierFullName, NumberFormatFloat, safeDivide } from "./HelperFunction";
export function mappingReportGrItemData(datas) {
    var mappingData = datas.map(function(v, i) {
        var mapping = {
            ลำดับที่: i + 1,
            วันที่ออกเอกสาร: moment(v.dimNewGr.doc_receive_date).format("DD/MM/YYYY"),
            เลขที่ใบรับสินค้า: v.dimNewGr.doc_number,
            เลขที่ใบสั่งซื้อ: v.dimNewGr.dimNewPurchaseOrder.doc_number,
            ผู้จัดจำหน่าย: getSupplierFullName(v.dimNewGr.dimSupplierInformation),
            รหัสสินค้า: v.dimProductPrice.dimProduct.sku,
            บาร์โค๊ด: v.dimProductPrice.barcode,
            ชื่อสินค้า: v.dimProductPrice.dimProduct.name_th,
            คลังจัดส่ง: v.dimWarehouse.name,
            หน่วย: "".concat(v.dimMasterUnit.name),
            อัตตราส่วนหน่วยย่อย: "".concat(v.ratio_unit),
            จำนวนรับ: v.receive_quantity,
            "ราคา/หน่วย": v.unit_price,
            ประเภทส่วนลด: v.dimMstVat ? v.dimMstVat.name : "-",
            "ส่วนลด/หน่วย": v.discount_price,
            ภาษี: v.dimMstVat.name,
            มูลค่าก่อนภาษี: v.total_price_before_vat,
            ภาษีมูลค่าเพิ่ม: v.total_price_include_vat - v.total_price_before_vat,
            มูลค่ารวมภาษี: v.total_price_include_vat,
            หมายเหตุ: v.remark,
            ผู้รับสินค้า: getEmpFullNameTHByEmpData(v.dimNewGr.createdNewGrEmp)
        };
        return mapping;
    });
    return mappingData;
}
export function mappingReportGrItemWithExpenseData(datas) {
    var mappingData = datas.map(function(v, i) {
        var exVatPriceByMstCategory = calPriceByMstCategoryPriceTaxSeparate(v.unit_price, 0, v.dimNewGr.dim_mst_price_category_id, 1, v.dimMstVat.value, true);
        var exVatWithDiscount = exVatPriceByMstCategory - (v.discount_price || 0);
        var exVatTotalCost = exVatWithDiscount + safeDivide(v.ex_vat_total_avg_discount_exp, v.receive_quantity);
        var exVatUmsTotalCost = safeDivide(exVatTotalCost, v.ratio_unit);
        var totalPriceWithExpenseExVat = v.ex_vat_total_avg_discount_exp + v.total_price_before_vat;
        var mapping = {
            ลำดับที่: i + 1,
            วันที่ออกเอกสาร: moment(v.dimNewGr.doc_receive_date).format("DD/MM/YYYY"),
            เลขที่ใบรับสินค้า: v.dimNewGr.doc_number,
            เลขที่ใบสั่งซื้อ: v.dimNewGr.dimNewPurchaseOrder.doc_number,
            ผู้จัดจำหน่าย: getSupplierFullName(v.dimNewGr.dimSupplierInformation),
            รหัสสินค้า: v.dimProductPrice.dimProduct.sku,
            บาร์โค๊ด: v.dimProductPrice.barcode,
            ชื่อสินค้า: v.dimProductPrice.dimProduct.name_th,
            คลังจัดส่ง: v.dimWarehouse.name,
            หน่วย: "".concat(v.dimMasterUnit.name),
            อัตตราส่วนหน่วยย่อย: "".concat(v.ratio_unit),
            จำนวนรับ: v.receive_quantity,
            "ราคา/หน่วย": v.unit_price,
            ประเภทส่วนลด: v.dimMstVat ? v.dimMstVat.name : "-",
            "ส่วนลด/หน่วย": v.discount_price,
            ภาษี: v.dimMstVat.name,
            มูลค่าก่อนภาษี: v.total_price_before_vat,
            ภาษีมูลค่าเพิ่ม: v.total_price_include_vat - v.total_price_before_vat,
            มูลค่ารวมภาษี: v.total_price_include_vat,
            "ค่าใช้จ่ายเพิ่มเติมต่อรายการ (Ex.VAT)	": v.ex_vat_total_avg_discount_exp,
            "รวมต้นทุนทั้งหมด Ex.VAT": NumberFormatFloat(totalPriceWithExpenseExVat, 4),
            "ค่าใช้จ่ายเพิ่มเติมต่อหน่วย Ex.VAT": v.ex_vat_unit_avg_discount_exp,
            "ต้นทุนรวมต่อรายการ(Ex.VAT)": NumberFormatFloat(exVatTotalCost, 4),
            "ต้นทุนรวม UMS(Ex.VAT)": NumberFormatFloat(exVatUmsTotalCost, 4),
            หมายเหตุ: v.remark,
            ผู้รับสินค้า: getEmpFullNameTHByEmpData(v.dimNewGr.createdNewGrEmp)
        };
        return mapping;
    });
    return mappingData;
}
