import { nanoid } from "nanoid";
export function getSupplierProductName(supNameList) {
    console.log("supNameList", supNameList);
    return Array.isArray(supNameList) && supNameList.length > 0 ? supNameList[0] || null : null;
}
export function getProductInit() {
    return {
        rowID: nanoid(),
        productID: "",
        productCode: "",
        productName: "",
        productBarcode: "",
        supplierCode: "",
        supplierName: "",
        warehouseID: "",
        warehouseName: "",
        productUnit: "",
        productQty: "",
        productOrdered: "",
        ums: "",
        productStock: "",
        productPrice: "",
        discountType: "",
        discount: "",
        discountPercent: 0,
        tax: "",
        price_before_tax: "",
        vat: "",
        note: "",
        price_after_tax: "",
        is_checked: false,
        edit: false
    };
}
